<script>
import { ArrowRightIcon } from "vue-feather-icons";

export default {
  methods: {
  },
  components: { ArrowRightIcon },
  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <!-- Features start -->
    <section class="section bg-light" id="features">
      <div class="container">
        <div class="row justify-content-center mb-5" data-aos="fade-up">
          <div class="col-lg-7 text-center">
            <h2 class="fw-bold fs-1">Distribute Your Music
              Everywhere</h2>
            <p class="text-muted">
              You create your best music, and we will distribute it across the best platforms in the world.
            </p>
          </div>
        </div>

        <div class="row align-items-center mb-5">
          <div class="col-md-5 order-2 order-md-1 mt-md-0 mt-5" data-aos="fade-up">
            <h2 class="mb-4">Your Official Audio Release Partner</h2>
            <p class="text-muted mb-2 fs-6">
              Launch your official music videos in style. We help you quickly get your music videos live on the most
              popular social platforms and earn royalties. We have you covered from setup and launch to video uploads
              and rights management.
            </p>

          </div>
          <div class="col-md-6 ms-md-auto order-1 order-md-2" data-aos="fade-left">
            <div class="position-relative">
              <div class="ms-5 features-img">
                <img src="@/assets/f_02.jpg" alt="" class="img-fluid d-block ms-auto rounded shadow" />
              </div>
              <img src="@/assets/images/dot-img.png" alt="" class="dot-img-left" />
            </div>
          </div>
        </div>
        <!-- end row -->
        <div class="row align-items-center section mb-5">
          <div class="col-md-6" data-aos="fade-right">
            <div class="position-relative mb-md-0 mb-5">
              <div class="me-5 features-img">
                <img src="@/assets/f_03.jpg" alt="" class="img-fluid d-block ms-auto rounded shadow" />
              </div>
              <img src="@/assets/images/dot-img.png" alt="" class="dot-img-right" />
            </div>
          </div>
          <div class="col-md-5 ms-md-auto" data-aos="fade-up">
            <h2 class="mb-4">
              Your Official Video Release Partner
            </h2>
            <p class="text-muted mb-5">
              Launch your official music videos in style. We help you quickly get your music videos live on the most
              popular social platforms and earn royalties. We have you covered from setup and launch to video uploads
              and rights management.
            </p>

          </div>
        </div>
        <!-- end row -->

        <div class="row align-items-center mb-5">
          <div class="col-md-5 order-2 order-md-1 mt-md-0 mt-5" data-aos="fade-up">
            <h2 class="mb-4">Own Your Music</h2>
            <p class="text-muted mb-2">
              Orginal Your Music. Empower yourself as the creator of your music. We stand by the artist’s freedom in
              crafting their work. Believe in yourself, as we believe in you. </p>

          </div>
          <div class="col-md-6 ms-md-auto order-1 order-md-2" data-aos="fade-left">
            <div class="position-relative">
              <div class="ms-5 features-img">
                <img src="@/assets/Own-Your-Music.jpg" alt="" class="img-fluid d-block ms-auto rounded shadow" />
              </div>
              <img src="@/assets/images/dot-img.png" alt="" class="dot-img-left" />
            </div>
          </div>
        </div>

        <!-- end row -->



      </div>
      <!-- end container -->
    </section>
    <!-- Features end -->


    <!-- Cta end -->
  </div>
</template>