<script>
import { BoxIcon, LayersIcon, ActivityIcon } from "vue-feather-icons";

export default {
  components: { BoxIcon, LayersIcon, ActivityIcon },
  data() {
    return {};
  },
};
</script>

<template>
  <!-- Services start -->
  <section class="section" id="services">
    <div class="container-fluid px-5">
      <div class="row justify-content-center mb-5" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
        <div class="col-lg-7 text-center">
          <h2 class="fw-bold">Our Services</h2>
          <br>
          <p class="text-muted">
            We are the right place to spread your music around the world.
            If you are a musician, Register an account today. We are waiting for you.
          </p>
        </div>
      </div>

      <div class="row rowx">

        <div class="col-lg-6 item-card m-4" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
          <div class="service-box text-center px-2 py-2 position-relative mb-4">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <layers-icon class=""></layers-icon>
              </div>

              <h4 class="mb-3 font-size-22">Awesome Support</h4>
              <p class="text-muted mb-0">
                Get instant help via chat, email, or phone, supported by friendly staff. Access FAQs and tutorials for
                quick answers, with technical troubleshooting assistance available.
              </p>

            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-lg-6 item-card m-4" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
          <div class="service-box text-center px-2 py-2 position-relative mb-4  ">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <img height="30px" width="30px" src="@/assets/music_dist.png" alt="" srcset="">

              </div>
              <h4 class="mb-3 font-size-22">Distribution</h4>
              <p class="text-muted mb-0">
                Launch your music on over 100 platforms. Spotify, Deezer, Pandora, and countless others. Distribute your
                music with us today and cultivate a global fanbase. Watch your music spread across every social media
                platform. As your release date approaches, anticipate streams and downloads. This marks the beginning of
                your earnings. Boost your income with each download and stream. </p>
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-lg-6 item-card m-4" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
          <div class="service-box text-center px-2 py-2 position-relative mb-4">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <ActivityIcon class=""></ActivityIcon>
              </div>
              <h4 class="mb-3 font-size-22">Promotion</h4>
              <p class="text-muted mb-0">
                Boost your music with expert promotion across major platforms. Gain visibility through targeted
                campaigns and reach millions of listeners worldwide. Let our team help you build your audience and grow
                your brand.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 item-card m-4" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
          <div class="service-box text-center px-2 py-2 position-relative mb-4">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <ActivityIcon class=""></ActivityIcon>
              </div>
              <h4 class="mb-3 font-size-22">Publishing</h4>
              <p class="text-muted mb-0">
                Your compositions stand to earn significant revenue through streaming. Secure publishing royalties with
                each stream on platforms like Spotify, Apple Music, and TikTok. Additionally, earn royalties for views
                on YouTube, radio play, or live performances. Every download and synchronization brings in income. Your
                original compositions are generating publishing royalties. We'll actively promote your track for
                potential placements in TV and film.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 item-card m-4" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
          <div class="service-box text-center px-2 py-2 position-relative mb-4">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <ActivityIcon class=""></ActivityIcon>
              </div>
              <h4 class="mb-3 font-size-22">
                YouTube Channel Monetization
              </h4>
              <p class="text-muted mb-0">
                Your income stems from unauthorized utilization of your original material. Monitor uploads to safeguard
                your digital rights. We ensure your content is protected. Maximize earnings by partnering with
                advertiser brands. Moreover, aid TikTok and Instagram influencers in establishing monetization, live
                streams, and merchandising on YouTube.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 item-card " data-aos="fade-up" data-aos-anchor-placement="top-bottom">
          <div class="service-box text-center px-2 py-2 position-relative mb-4">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <ActivityIcon class=""></ActivityIcon>
              </div>
              <h4 class="mb-3 font-size-22">
                Rights Management
              </h4>
              <p class="text-muted mb-0">
                We ensure the safeguarding of your music and its revenue stream. Our services include Facebook/Instagram
                rights management for music publishers, record labels, and music production libraries. Enhance your
                income from music uploaded on YouTube, Facebook, Instagram, and other platforms with tailored agreements
                and policies.
              </p>
            </div>
          </div>
        </div>


        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->

    <br><br>
    <br><br>
    <br><br>

    <section class="section bg-gradient-primary">
      <div class="bg-overlay-img" :style="{
        'background-image':
          'url(' + require('@/assets/images/demos.png') + ')',
      }"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="text-center">
              <h1 class="text-white mb-4">Why join us
                Why work with us</h1>
              <p class="text-white mb-5 font-size-16">
                Because we are a licensed organization, we understand the value of your music. We will do what is right
                for you.
              </p>
              <router-link to="/sign-up">
                <a href="#" class="btn btn-lg btn-light">Join owe For Free </a>

              </router-link>
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>

  </section>

  <!-- Services end -->




</template>


<style>
.rowx {

  justify-content: center;


}

/* Individual card styling */
.item-card {
  max-width: 450px;
  cursor: pointer;
  background: transparent;
  backdrop-filter: blur(15px);
  box-shadow: 0 4px 15px rgba(55, 55, 55, 0.2);
  border: 1px solid #ffffff;
  border-radius: 30px;
  animation: border-color-change 3s infinite linear;
}

/* Keyframes for border animation */
@keyframes border-color-change {
  0% {
    border-color: rgb(0, 0, 0);
    /* Red */
  }

  25% {
    border-color: rgb(255, 255, 255);
    /* Yellow */
  }

  50% {
    border-color: rgb(0, 0, 0);
    /* Green */
  }

  75% {
    border-color: rgb(255, 255, 255);
    /* Blue */
  }

  100% {
    border-color: rgb(0, 0, 0);
    /* Back to Red */
  }
}

/* Keyframes for auto-scroll */
@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}
</style>